@import url(https://fonts.googleapis.com/css2?family=Fira+Sans:wght@200;300;400;500;600;700;800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  color: white;
  overflow-x: unset;
  font-family: 'Fira Sans', sans-serif;
}

html,
body {
  padding: 0 !important;
  margin: 0 !important;
  background-color: #854dbb;
  background-image: url(/static/media/bubbles2.99073e54.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-size: 2500px auto;
  background-position: top center;
  overflow-x: hidden;
}

h1{
  font-size: xxx-large !important;
}

.app-base {
  padding: 0 !important;
}

.center {
  display: flex;
  align-items: center;
  height: 100%
}

.z-10{
  position: relative;
  z-index: 10;
}

.justify-text{
  text-align: justify;
}

.card-img-icon-left,
.card-img-icon-right,
.card-img-icon-center{
  position: absolute;
  border-radius: 100px;
  background: rgba( 255, 255, 255, 0.20 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 15.0px );
  -webkit-backdrop-filter: blur( 15.0px );
  width: 100px;
  height: 100px;
  padding: 20px 25px;
  top: -60px;
  left: -50px;
}

.card-img-icon-right{
  left: inherit;
  right: -50px;
}

.card-img-icon-center{
  left: 44%;
}

li{
  list-style-type: none;
}


@media screen and (max-width:600px) {
  .card-img-icon-left{
    left: 0px;
  }
  .card-img-icon-right{
    right: 0px;
  }

  .card-img-icon-center{
  left: 40%;
  top: -70px
  }

}

@media screen and (min-width: 2500px) {
  body{
    background-size: 4000px auto;
  }
}

@media screen and (max-width: 350px) {
  h1,.footer-logo{
    font-size: x-large !important;
  }
  
}

.data-block{
  margin: 50px 10px;
}

.big-space{
  height: 50px;
  width: 100vw;
}

.big-text{
  font-size: x-large;
  min-height: 75px;
}

.advantage-icon{
  /* text-align: center; */
  margin-left: 20px;
}

.show-mobile{
  display: none;
}

@media screen and ( max-width: 990px) {
  .show-mobile{
    display: block;
  }

  .hide-mobile{
    display: none;
  }
}


.fw-100{
  font-weight: 100 !important;
}

.w-fit-content{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.discord-link{
  display: inline-block;
  padding: 0;
}

.how-to-container{
  height: 800px;
}

.nav-tabs{
  border: none;
}

.nav-tabs > li{
  width: 50%;
}

.nav-tabs > li > button{
  width: 100%;
  text-align: center;
  color: white;
  border: none;
  font-size: large;
}

.nav-tabs > li > button:hover{
  color: white;
  border: none;
  border-color: #e9ecef00 ;
}

button.active{
  color: white !important;
  background: none !important;
  text-shadow: 0px 0px 14px rgba(255, 255, 255, 0.54);
  transition: .2s;
  font-weight: bold;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link{
  border: none;
}

#uncontrolled-tab-example-tabpane-wallet{
  max-width: 800px;
  margin: 0 auto;
}

.carousel-caption{
  background-color: #00000044;
  border-radius: 20px 20px 0 0 ;
  color: black !important;
}

.landing-container{
  height: 100vh;
}

.landing-background{
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
}

.landing-background>img{
  /* min-height: 100vh; */
  width: 100vw;
  /* height: 100vh; */
}

.address-block{
  background-color: #0000005c;
  border-radius: 10px;
  padding: 10px 20px;
  margin: 0 auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 200px;
}

.address-block>code{
  color: white;
}

.address-block>.pointer{
  float: right;
}

.learn-how-button{
  background: none;
  border: none;
  font-size: larger;
  color: white;
  font-weight: 600;
  cursor: pointer;
}

.learn-how-button:hover{
  text-shadow: 0px 0px 14px rgba(255, 255, 255, 0.54);
  transition: .2s;
}

.glass-card{
  background: rgba( 255, 255, 255, 0.20 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 15.0px );
  -webkit-backdrop-filter: blur( 15.0px );
  border-radius: 10px;
  padding: 50px 80px;
  max-width: 800px;
}

.float-right{
  float: right;
}

.card-img{
  position: absolute;
  border-radius: 100px;
  background: rgb(71, 105, 255);
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 15.0px );
  -webkit-backdrop-filter: blur( 15.0px );
  width: 100px;
  height: 100px;
  padding: 20px 25px;
  top: -60px;
  left: 45%;
}

.card-img>img{
  width: 50px;
}

.text-center{
  text-align: center;
}

.feat-small {
  transform: scale(0.5);
  display: inline-block;
  color: #494795;
}

.pointer{
  cursor: pointer;
}

@media screen and (max-width:860px) {
  .card-img{
    left: 40%;
  }

  .glass-card{
    padding: 50px 20px;  }

  .w-75{
    width: 100% !important;
  }

  .mobile-padding{
    padding: 0 20px;
  }
}

@media screen and (max-width:425px) {
  .card-img{
    left: 36%;
  }
}

@media screen and (max-height: 750px) {

  #home{
    margin-top: 75px;
    margin-bottom: 150px;
    height: 100%;
    display: block;
  }

  .glass-card{
    margin-top: 150px;
  }
}

@media screen and (max-height: 900px) {
  .glass-card{
    margin-top: 100px;
  }
}

.social-icon>img{
  width: 150px;
  opacity: .8;
}

.social-icon>img:hover{
  width: 145px;
  margin-left: 2.5px;
  opacity: 1;
  transition: .2s;
}

.twitter{
  margin-top: 20px;
}

@media screen and (max-width: 990px) {
  .social-icon>img{
    width: 100px;
  }
  .twitter{
    margin-top: 13px;
  }

  .social-icon>img:hover{
    width: 95px;
    margin-left: 2.5px;
    opacity: 1;
    transition: .2s;
  }
}

@media screen and (max-width: 450px) {
  .nav-link.social-icon{
    padding: 0;
  }

  .social-icon>img{
    width: 70px;
  }
  .twitter{
    margin-top: 7px;
  }

  .social-icon>img:hover{
    width: 65px;
    margin-left: 2.5px;
    opacity: 1;
    transition: .2s;
  }
}

.data-block{
  margin: 50px 10px;
}

.big-space{
  height: 50px;
  width: 100vw;
}

.big-text{
  font-size: x-large;
  min-height: 75px;
}

.advantage-icon{
  /* text-align: center; */
  margin-left: 20px;
}

.vertical-flex{
  flex-direction: column;
}

.content-container{
  /* -webkit-box-shadow: 0px -20px 180px 2px #00000080; 
  box-shadow: 0px -20px 180px 2px #00000030; */
  position: relative;
  z-index: 10;
  width: 100vw;
  left: 0;
  padding: 40px;
  /* background-color: #4c36d4; */
}

@media screen and (max-width: 600px) {
  .content-container{
    padding: 0px;
  }
}

.footer-container {
  background: linear-gradient(180deg, #FFFFFF22 0%, rgba(255, 255, 255, 0) 100%);
  margin: 0;
  padding: 100px 50px;
}

.footer-logo {
  font-weight: 400;
  font-size: xxx-large;
}

.a-no-style, .a-no-style:hover, .a-no-style:focus{
  color: white;
  text-decoration: underline;
}

@media screen and (max-width: 725px) {
  .footer-container {

    padding: 50px 20px;
    margin-top: 20px;
  }

}

.topbar-container{
  position: fixed;
  width: 100%;
  left: 0;
  background: rgba( 255, 255, 255, 0.20 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 15.0px );
  -webkit-backdrop-filter: blur( 15.0px );
  z-index: 15;
}

.navbar-brand{
  color: #fff;
  line-height: 35px;
  position: relative;
}

