.how-to-container{
  height: 800px;
}

.nav-tabs{
  border: none;
}

.nav-tabs > li{
  width: 50%;
}

.nav-tabs > li > button{
  width: 100%;
  text-align: center;
  color: white;
  border: none;
  font-size: large;
}

.nav-tabs > li > button:hover{
  color: white;
  border: none;
  border-color: #e9ecef00 ;
}

button.active{
  color: white !important;
  background: none !important;
  text-shadow: 0px 0px 14px rgba(255, 255, 255, 0.54);
  transition: .2s;
  font-weight: bold;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link{
  border: none;
}

#uncontrolled-tab-example-tabpane-wallet{
  max-width: 800px;
  margin: 0 auto;
}
