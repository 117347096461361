.landing-container{
  height: 100vh;
}

.landing-background{
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
}

.landing-background>img{
  /* min-height: 100vh; */
  width: 100vw;
  /* height: 100vh; */
}

.address-block{
  background-color: #0000005c;
  border-radius: 10px;
  padding: 10px 20px;
  margin: 0 auto;
  width: fit-content;
  min-width: 200px;
}

.address-block>code{
  color: white;
}

.address-block>.pointer{
  float: right;
}

.learn-how-button{
  background: none;
  border: none;
  font-size: larger;
  color: white;
  font-weight: 600;
  cursor: pointer;
}

.learn-how-button:hover{
  text-shadow: 0px 0px 14px rgba(255, 255, 255, 0.54);
  transition: .2s;
}

.glass-card{
  background: rgba( 255, 255, 255, 0.20 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 15.0px );
  -webkit-backdrop-filter: blur( 15.0px );
  border-radius: 10px;
  padding: 50px 80px;
  max-width: 800px;
}

.float-right{
  float: right;
}

.card-img{
  position: absolute;
  border-radius: 100px;
  background: rgb(71, 105, 255);
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 15.0px );
  -webkit-backdrop-filter: blur( 15.0px );
  width: 100px;
  height: 100px;
  padding: 20px 25px;
  top: -60px;
  left: 45%;
}

.card-img>img{
  width: 50px;
}

.text-center{
  text-align: center;
}

.feat-small {
  transform: scale(0.5);
  display: inline-block;
  color: #494795;
}

.pointer{
  cursor: pointer;
}

@media screen and (max-width:860px) {
  .card-img{
    left: 40%;
  }

  .glass-card{
    padding: 50px 20px;  }

  .w-75{
    width: 100% !important;
  }

  .mobile-padding{
    padding: 0 20px;
  }
}

@media screen and (max-width:425px) {
  .card-img{
    left: 36%;
  }
}

@media screen and (max-height: 750px) {

  #home{
    margin-top: 75px;
    margin-bottom: 150px;
    height: 100%;
    display: block;
  }

  .glass-card{
    margin-top: 150px;
  }
}

@media screen and (max-height: 900px) {
  .glass-card{
    margin-top: 100px;
  }
}
