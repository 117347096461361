.footer-container {
  background: linear-gradient(180deg, #FFFFFF22 0%, rgba(255, 255, 255, 0) 100%);
  margin: 0;
  padding: 100px 50px;
}

.footer-logo {
  font-weight: 400;
  font-size: xxx-large;
}

.a-no-style, .a-no-style:hover, .a-no-style:focus{
  color: white;
  text-decoration: underline;
}

@media screen and (max-width: 725px) {
  .footer-container {

    padding: 50px 20px;
    margin-top: 20px;
  }

}
