.topbar-container{
  position: fixed;
  width: 100%;
  left: 0;
  background: rgba( 255, 255, 255, 0.20 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 15.0px );
  -webkit-backdrop-filter: blur( 15.0px );
  z-index: 15;
}

.navbar-brand{
  color: #fff;
  line-height: 35px;
  position: relative;
}
