
.fw-100{
  font-weight: 100 !important;
}

.w-fit-content{
  width: fit-content;
}

.discord-link{
  display: inline-block;
  padding: 0;
}
