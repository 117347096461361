.data-block{
  margin: 50px 10px;
}

.big-space{
  height: 50px;
  width: 100vw;
}

.big-text{
  font-size: x-large;
  min-height: 75px;
}

.advantage-icon{
  /* text-align: center; */
  margin-left: 20px;
}

.show-mobile{
  display: none;
}

@media screen and ( max-width: 990px) {
  .show-mobile{
    display: block;
  }

  .hide-mobile{
    display: none;
  }
}
