.social-icon>img{
  width: 150px;
  opacity: .8;
}

.social-icon>img:hover{
  width: 145px;
  margin-left: 2.5px;
  opacity: 1;
  transition: .2s;
}

.twitter{
  margin-top: 20px;
}

@media screen and (max-width: 990px) {
  .social-icon>img{
    width: 100px;
  }
  .twitter{
    margin-top: 13px;
  }

  .social-icon>img:hover{
    width: 95px;
    margin-left: 2.5px;
    opacity: 1;
    transition: .2s;
  }
}

@media screen and (max-width: 450px) {
  .nav-link.social-icon{
    padding: 0;
  }

  .social-icon>img{
    width: 70px;
  }
  .twitter{
    margin-top: 7px;
  }

  .social-icon>img:hover{
    width: 65px;
    margin-left: 2.5px;
    opacity: 1;
    transition: .2s;
  }
}
