.vertical-flex{
  flex-direction: column;
}

.content-container{
  /* -webkit-box-shadow: 0px -20px 180px 2px #00000080; 
  box-shadow: 0px -20px 180px 2px #00000030; */
  position: relative;
  z-index: 10;
  width: 100vw;
  left: 0;
  padding: 40px;
  /* background-color: #4c36d4; */
}

@media screen and (max-width: 600px) {
  .content-container{
    padding: 0px;
  }
}
