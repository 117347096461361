@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@200;300;400;500;600;700;800&display=swap');

* {
  color: white;
  overflow-x: unset;
  font-family: 'Fira Sans', sans-serif;
}

html,
body {
  padding: 0 !important;
  margin: 0 !important;
  background-color: #854dbb;
  background-image: url("./components/landing/assets/bubbles2.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-size: 2500px auto;
  background-position: top center;
  overflow-x: hidden;
}

h1{
  font-size: xxx-large !important;
}

.app-base {
  padding: 0 !important;
}

.center {
  display: flex;
  align-items: center;
  height: 100%
}

.z-10{
  position: relative;
  z-index: 10;
}

.justify-text{
  text-align: justify;
}

.card-img-icon-left,
.card-img-icon-right,
.card-img-icon-center{
  position: absolute;
  border-radius: 100px;
  background: rgba( 255, 255, 255, 0.20 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 15.0px );
  -webkit-backdrop-filter: blur( 15.0px );
  width: 100px;
  height: 100px;
  padding: 20px 25px;
  top: -60px;
  left: -50px;
}

.card-img-icon-right{
  left: inherit;
  right: -50px;
}

.card-img-icon-center{
  left: 44%;
}

li{
  list-style-type: none;
}


@media screen and (max-width:600px) {
  .card-img-icon-left{
    left: 0px;
  }
  .card-img-icon-right{
    right: 0px;
  }

  .card-img-icon-center{
  left: 40%;
  top: -70px
  }

}

@media screen and (min-width: 2500px) {
  body{
    background-size: 4000px auto;
  }
}

@media screen and (max-width: 350px) {
  h1,.footer-logo{
    font-size: x-large !important;
  }
  
}
